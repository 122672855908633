
/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.pagination a{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 34px;
    height: 34px;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 5px;
    margin: 0 2px;
    -webkit-transition: all ease-out .15s;
    transition: all ease-out .15s;
}
.pagination a:not(.pagination__current) {
    font-weight:400;
    color: #757575 !important;
    cursor: pointer;
    border: 1px solid var(--border-color);
    border-radius: 6px;
}
.pagination__backward, .pagination__prev, .pagination__next, .pagination__forward{
    color: var(--v-primary-base) !important;
}
.pagination__backward .v-icon,
.pagination__prev .v-icon,
.pagination__next .v-icon,
.pagination__forward .v-icon{
    color: inherit;
}
.pagination .pagination__prev{
    margin-right: 16px;
}
.pagination .pagination__next{
    margin-left: 16px;
}
.pagination .pagination__current {
    color: var(--v-primary-base);
    cursor: default;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .pagination{
        margin-top: 60px;
    }
    .pagination a{
        font-size: 1.8rem;
    }
}
